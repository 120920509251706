.containerLine {
    width: 100%;
    @include flexbox;
    @include justify-content(center);
    .line {
        -webkit-text-fill-color: black !important;
        width: 2.3rem;
        height: 0.4rem;
        background-color: map-get($theme1, color-base);
    }
}