.containerList {
    cursor: pointer;
    @include flexbox;
    @include align-items(center);
    @include justify-content(space-around);
    background-color: white;
    padding-top: rem(16px);
    padding-bottom: rem(16px);
    border-bottom: 1px solid map-get($theme1, color-gray-400);
    &.no-bottom-border{
        border-bottom: none;
    }
    &:hover {
        background-color: rgb(245, 245, 255);
    }
    &.validated {
        background-color: rgb(242, 242, 255);
    }
    .containerImage {
        height: 3rem;
        width: 3rem;
        border-radius: 3rem;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .containerImageDescription {
        @include flexbox;
        @include justify-content(center);
        flex-wrap: wrap;
        height: 3rem;
        width: 5rem;
        img {
            height: 2rem;
        }
        .imageDescription {
            width: 100%;
            text-align: center;
            font-size: .8rem;
            font-weight: 100;
            color: map-get($theme1, color-gray);
        }
    }
    .dataList {
        text-align: left;
        font-size: .85rem;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 20%;
        padding: .2rem;
        &.name {
            text-align: left;
            font-size: 1.1rem;
            line-height: 1;
        }
    }
    &.list-header {
        display: none;
        height: auto;
        background-color: map-get($theme1, color-accent-white);
        border-bottom: solid 2px map-get($theme1, color-gray-400) !important;
        .dataList {
            border-left: 1px solid map-get($theme1, color-gray-400);
            padding-top: .1rem;
            padding-bottom: .1rem;
            color: map-get($theme1, color-gray-800);
            &:nth-child(1) {
                border-left: none;
            }
        }
    }
    .date-timeline {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            height: 15px;
            top: 17px;
            left: 2px;
            width: 1px;
            border-left: dashed 1px map-get($theme1, color-gray-800);
        }
        span {
            display: block;
            &::before {
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: solid 2px map-get($theme1, color-gray-800);
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                margin-left: -2px;
            }
            &:nth-child(2) {
                &::before {
                    background-color: map-get($theme1, color-gray-800);
                }
            }
        }
    }
}

#table-filters {
    display: none;
    justify-content: space-between;
    flex-direction: column;
    .mat-form-field-flex {
        flex-direction: row-reverse;
    }
    .date-filter .mat-form-field-flex,
    .search-bar .mat-form-field-flex {
        flex-direction: row-reverse;
    }
    .mat-form-field {
        margin: 0 8px;
        max-width: 65px;
    }
    .search-bar {
        max-width: 300px;
    }
}


.ad-thumbnail{
    min-width: 57px;
    min-height: 97px;
    width: 57px;
    max-height: fit-content;
    border-radius: 5px;
    padding: 1px;
    border: solid black 1px;
    margin-right: 10px;
    background-color: map-get($theme1, color-accent-white);
}

@include mq-tablet {
    .containerList {
        &.list-header {
            display: flex;
        }
        .dataList {
            font-size: .9rem;
            width: auto;
            padding: .5rem .9rem;
        }
        .date-timeline {
            &::before {
                left: -1px;
                height: 25px;
            }
            span {
                &::before {
                    margin-left: -5px;
                }
            }
        }
    }
    #table-filters {
        display: flex;
        flex-direction: row;
        .mat-form-field-flex {
            .mat-icon {
                display: none;
            }
        }
        .date-filter .mat-form-field-flex,
        .search-bar .mat-form-field-flex {
            flex-direction: row-reverse;
            .mat-icon {
                display: block;
            }
        }
        .mat-form-field {
            margin: 0 8px;
            max-width: 120px;
        }
        .search-bar {
            max-width: 450px;
        }
    }
    .ad-thumbnail{
        min-width: 38px;
        min-height: 59px;
        width: 38px;
    }
}

@include mq-desktop {
    .containerList {
        &.list-header {
            display: flex;
        }
        .dataList {
            font-size: 1rem;
            width: auto;
            padding: .5rem .9rem;
        }
        .date-timeline {
            &::before {
                left: -1px;
                height: 25px;
            }
            span {
                &::before {
                    margin-left: -5px;
                }
            }
        }
    }
    #table-filters {
        display: flex;
        flex-direction: row;
        .mat-form-field-flex {
            .mat-icon {
                display: none;
            }
        }
        .date-filter .mat-form-field-flex,
        .search-bar .mat-form-field-flex {
            flex-direction: row-reverse;
            .mat-icon {
                display: block;
            }
        }
        .mat-form-field {
            margin: 0 8px;
            max-width: 120px;
        }
        .search-bar {
            max-width: 450px;
        }
    }
    .ad-thumbnail{
        min-width: 38px;
        min-height: 59px;
        width: 38px;
    }
}