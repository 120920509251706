input,
select,
textarea {    font-family: "Poppins", sans-serif !important;}

input {}

select {}

textarea {}

.errors {
    padding: 0rem rem(27.2px);
    font-size: 0.9rem;
    color: red;
    display: block;
    &.noPadding {
        padding: 0rem;
    }
}

.success {
    color: green;
}

// CHECKBOX
.container-check {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-top: 12px;
    cursor: pointer;
    font-size: 0.8rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    height: 25px;
    width: 25px;
    background: none;
    border: 1px solid;
    &.dark {
        border-color: map-get($theme1, color-base);
        .container-check:hover input~.checkmark {
            background-color: map-get($theme1, color-auxiliar);
        }
        /* When the checkbox is checked, add a blue background */
        .container-check input:checked~.checkmark {
            background-color: map-get($theme1, color-base);
        }
    }
    &.white {
        border-color: map-get($theme1, color-accent);
        .container-check:hover input~.checkmark {
            background-color: map-get($theme1, color-off);
        }
        /* When the checkbox is checked, add a blue background */
        .container-check input:checked~.checkmark {
            background-color: map-get($theme1, color-accent);
        }
    }
}


/* On mouse-over, add a grey background color */


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container-check input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container-check .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

:host input:-internal-autofill-selected {
    -webkit-text-fill-color: black !important;
}

.mat-form-field-wrapper {
    padding-bottom: 0.9em;
}

::ng-deep .mat-form-field-infix {
    border-top: 0.3rem !important;
}


::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.15em 0 1em 0;
}

.formNoLines {
     ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
        border-radius: 10px 10px 10px 10px !important;
    }
     ::ng-deep .mat-form-field-underline {
        display: none;
    }
     ::ng-deep .mat-form-field {
        line-height: 1 !important;
        padding-bottom: 0.3rem !important;
    }
     ::ng-deep .mat-form-field-wrapper {
        margin-bottom: -0.3em;
    }
     ::ng-deep .mat-form-field-wrapper .mat-form-field-flex {
        padding: 0.2em 0.7em 0 0.7em;
    }
}

.mat-form-field {
    .mat-form-field-outline-thick {
        color: map-get($theme2, gray-300);
    }
    &:hover .mat-form-field-outline-thick {
        color: map-get($theme1, color-base);
    }
    &.mat-focused .mat-form-field-outline {
        color: map-get($theme1, color-base) !important;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-bottom: 3px solid !important;
        .mat-form-field-outline-start {
            border-bottom-left-radius: 0px !important;
        }
        .mat-form-field-outline-end {
            border-bottom-right-radius: 0px !important;
        }
        input {
            background-color: white;
        }
    }
    &.mat-form-field-invalid .mat-form-field-outline {
        color: red !important;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-bottom: 3px solid !important;
        .mat-form-field-outline-start {
            border-bottom-left-radius: 0px !important;
        }
        .mat-form-field-outline-end {
            border-bottom-right-radius: 0px !important;
        }
        input {
            background-color: white;
        }
    }
}

.material-icons.errors {
    display: inline;
    padding: 0;
    margin-right: 2px;
    font-size: 22px;
    vertical-align: middle;
}

.mat-error,
mat-error {
    color: black;
    font-size: 15px;
}

mat-form-field.mat-form-field-invalid {
    margin-bottom: 15px;
}

.mat-form-field-subscript-wrapper {
    padding: 0.5em 0em !important;
    font-size: 100% !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none !important;
}

.mat-radio-button {
    margin-right: 15px;
    margin-top: 10px;
    &.mat-radio-checked {
        .mat-radio-outer-circle {
            border-color: map-get($theme1, color-base) !important;
        }
        .mat-radio-inner-circle {
            background-color: map-get($theme1, color-base);
            transform: scale(0.6) !important;
        }
    }
}

.mat-mdc-form-field-infix {
    min-height: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: map-get($theme1, color-base) !important;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: map-get($theme1, color-base) !important;
}

.mat-mdc-text-field-wrapper{
    background-color: white;
}