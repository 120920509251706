@import '../01-settings/colors';
a {
    text-decoration: none;
    font-size: rem(14px);
    font-weight: 400;
    height: rem(20px);
    color: map-get($theme1, color-base) !important;
    font-family: "Poppins", sans-serif !important;
    cursor: pointer;
}
