@import '../01-settings/colors';
.display {
    font-size: rem(24px);
    font-weight: 400;
    min-height: rem(38px);
    color: black;
}

hr {
    &.dashed {
        border: dashed 1px map-get($theme1, color-gray-300);
    }
}

.border-bottom-gray{
    border-bottom: 1px solid map-get($theme1, color-gray-400);
}

h1 {
    font-size: rem(24px);
    font-weight: 600;
    color: map-get($theme2, black);
    &.light{
        color: white;
    }
}

h2 {
    font-size: rem(16px);
    font-weight: 500;
    color: map-get($theme2, black);
    &.light{
        color: white;
    }
    &.big {
        font-size: rem(24px);
        font-weight: 600;
        color: map-get($theme2, black);
    }
    &.bold{
        font-weight: 600;
    }
    &.bolder{
        font-weight: 800 ;
    }
}

h3 {
    font-size: rem(16px);
    font-weight: 600;
    height: rem(26px);
    color: map-get($theme2, black);
}

h4 {
    font-size: rem(14px);
    font-weight: 600;
    height: rem(24px);
    color: map-get($theme2, black);
}

h4 {
    font-size: rem(14px);
    font-weight: 600;
    height: rem(20px);
    color: black;
}