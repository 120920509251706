@import "../02-tools/colors";

:root {
    --meat-color-primary: #2B0AFF;
    --meat-color-secondary: #03073A;
    --meat-color-tertiary: #5260ff;
    --meat-color-success: #00CC66;
    --meat-color-warning: #ffc409;
    --meat-color-danger: #eb445a;
    --meat-color-dark: #222428;
    --meat-color-medium: #92949c;
    --meat-color-light: #f4f5f8;
    --meat-color-free: #00AB55;
    --meat-color-basic: #FF2E00;
    --mdc-outlined-text-field-outline-color: #D7D9E0;
}

$theme1: (
    color-base-000: #dcd6ff,
    color-base-100: #c2baf5,
    color-base-150: #a89df5,
    color-base-200: #8f7ff5,
    color-base-300: #7662f5,
    color-base-400: #543bf5,
    color-base: var(--meat-color-primary),
    color-base-600: #0822cc,
    color-base-700: #071ba3,
    color-base-800: #05157a,
    color-base-900: #040f5c,
    color-dark: var(--meat-color-secondary),
    color-auxiliar: var(--meat-color-tertiary),
    color-off: #FDFDFD,
    color-accent-white: #f7f8fa,
    color-gray-200: #edeef5,
    color-gray-300: #F2F2F3,
    color-gray-400: #d7d9e0,
    color-gray: #bcbecc,
    color-gray-600: #999bad,
    color-gray-700: #787b8f,
    color-gray-800: #5a5d70,
    color-gray-900: #454652,
    color-dark-accent: #272833,
    color-oliva-600: #25b825,
    color-mostaza-600: #f5c400,
    color-mandarina-600: #ff7029,
    color-cereza-500: #ff573d,
    color-cereza-600t: #ff2e1f,
);
$theme2: (
    black:#333,
    gray-100:#F4F4F4,
    gray-300:#a8a8a8,
    gray-500:#878787
);

.bck__base {
    background: map-get($theme1, color-base);
    color: map-get($default-colors, white-pure);
}

.bck__auxiliar {
    background: map-get($theme1, color-auxiliar);
    color: map-get($default-colors, white-pure);
}

.bck__accent {
    background: map-get($theme1, color-accent);
    color: map-get($default-colors, white-pure);
}

.bck__off {
    background: map-get($theme1, color-off);
    color: map-get($theme1, color-dark);
}

.bck__dark {
    background: map-get($theme1, color-dark);
    color: map-get($default-colors, white-pure);
}

.text-white{
    color: white !important;
}

.text-gray-100 {
    color: map-get($theme2, gray-300) !important;
}

.text-gray-300 {
    color: map-get($theme2, gray-300) !important;
}

.text-gray-500 {
    color: map-get($theme2, gray-300) !important;
}

.bg-gray-100 {
    background-color: map-get($theme2, gray-100) !important;
}

.bg-gray-300 {
    background-color: map-get($theme2, gray-300) !important;
}

.bg-gray-500 {
    background-color: map-get($theme2, gray-300) !important;
}


.dark-bg {
    background: var(--meat-color-dark);
}

.bg-primary-deg {
    background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
    color: white !important;
    ::ng-deep h2,::ng-deep p{
        color: white !important;
    }
}

.bg-primary-deg-v {
    background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */

/* Texto en color primario */
.text-primary {
    color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
    color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
    color: var(--meat-color-tertiary);
}


/* Colores de fondo */

/* Fondo en color primario */
.bg-primary {
    background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
    background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
    background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
    background-color: var(--meat-color-free) !important;
}

.bg-basic {
    background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
    background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
    background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
    background-color: var(--meat-color-secondary) !important;
}

.text-error {
    color: red !important;
}

.text-success {
    color: green!important;
}