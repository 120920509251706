@import '../01-settings/colors';

// Define the mixin for .btn-meat
@mixin btn-meat {
    font-size: rem(16px);
    font-weight: 500;
    padding: .5rem 1.1rem .55rem;
    line-height: 1.25rem;
    border-radius: rem(25px);
    cursor: pointer;
    min-height: rem(48px);
    min-width: rem(206px);
    height: fit-content;
    -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
    -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
    box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
    transition: all 0.2s ease-out;
    &:hover{
        opacity: 0.8;
        transform: scale(1.05);
        -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
    }
}

// Define the mixin for .btn-meat-small
@mixin btn-meat-small {
    font-size: .89rem;
    padding: .5rem 1.1rem .55rem;
    line-height: 1.25rem;
    border-radius: rem(25px);
    cursor: pointer;
    min-height: rem(40px);
    height: fit-content;
}

.btn-primary {
    @include btn-meat;
    border: none;
    color: white;
    background-color: map-get($theme1, color-base) !important;
    &.disabled{
        color: map-get($theme1, color-gray-800);
        background-color: map-get($theme1, color-gray-400) !important;
        &:hover{
            opacity: 1;
            transform: scale(1);
            -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
            -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
            box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
        }
    }
}

.btn-plain {
    @include btn-meat;
    border: none;
    color: map-get($theme1, color-base) !important;
    background-color: white !important;
    &:focus-visible{
        outline: none;
    }
}

.btn-primary-disabled {
    @include btn-meat;
    border: solid map-get($theme1, color-gray-500) 1px;
    color: map-get($theme1, color-gray-600);
    background-color: map-get($theme1, color-base-000) !important;
}

.btn-primary-extended {
    @include btn-meat;
    width: 100%;
    border: none;
    color: white;
    background-color: map-get($theme1, color-base) !important;
}

.btn-primary-extended-disabled {
    @include btn-meat;
    width: 100%;
    border: none;
    color: white;
    background-color: map-get($theme1, color-base-000) !important;
}

.btn-primary-700 {
    @include btn-meat;
    border: none;
    color: white;
    background-color: map-get($theme1, color-base-700) !important;
}

.btn-primary-900 {
    @include btn-meat;
    border: none;
    color: white;
    background-color: map-get($theme1, color-base-900) !important;
}

.btn-secondary-gray {
    @include btn-meat;
    border: solid map-get($theme1, color-gray) 1px;
    color: map-get($theme1, color-base-950);
    background-color: white !important;
}

.btn-circle-secondary-gray {
    @include btn-meat;
    border: solid map-get($theme1, color-gray) 1px;
    color: map-get($theme1, color-base-950);
    background-color: white !important;
    border-radius: 50px;
}

.btn-circle-secondary-gray-inverted {
    @include btn-meat;
    border: solid map-get($theme1, color-gray) 1px;
    color: map-get($theme1, color-base);
    background-color: map-get($theme1, color-accent) !important;
    border-radius: 50px;

    span {
        color: map-get($theme1, color-base);
    }
}

.btn-secondary-primary {
    @include btn-meat;
    border: solid map-get($theme1, color-base) 1px;
    color: map-get($theme1, color-base-950);
    background-color: white !important;
}

.btn-secondary-dark {
    @include btn-meat;
    border: solid map-get($theme1, color-base-900) 1px;
    color: map-get($theme1, color-base-950);
    background-color: white !important;
}

.btn-secondary-disabled {
    @include btn-meat;
    border: solid map-get($theme1, color-gray) 1px;
    color: map-get($theme1, color-gray-600);
    background-color: map-get($theme1, color-gray-200) !important;
}

.btn-tertiary-primary {
    @include btn-meat;
    border: none;
    color: map-get($theme1, color-base);
    background-color: transparent !important;
}

.btn-tertiary-primary-700 {
    @include btn-meat;
    border: none;
    color: map-get($theme1, color-base);
    background-color: transparent !important;
}

.btn-tertiary-primary-900 {
    @include btn-meat;
    border: none;
    color: map-get($theme1, color-base);
    background-color: transparent !important;
}

.btn-tertiary-disabled {
    @include btn-meat;
    border: none;
    color: map-get($theme1, color-gray-600);
    background-color: transparent !important;
}

.btn-google {
    font-size: .89rem;
    font-weight: bold;
    padding: .3rem 1.1rem .3rem;
    line-height: 1.25rem;
    border-radius: rem(5px);
    cursor: pointer;
    width: 100%;
    border: solid map-get($theme1, color-gray) 1.5px;
    color: black;
    background-color: white !important;
}

.circleButton {
    display: flex;
    align-items: center;
    border: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: map-get($theme1, color-base);

    span {
        color: white;
    }
}

.iconButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    background: transparent;

    span {
        color: map-get($theme1, color-accent);
    }
}

.btn-border-blue-bottom {
    padding: 2px 0px;
    margin: auto 10px;
    font-size: 1rem;
    line-height: 1.25rem;
    border: none;
    background: none !important;
    cursor: pointer;
    color: #5A5D70;
    overflow: visible;

    &.active {
        color: black !important;
        font-weight: bold;

        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 4px;
            border-radius: 10px;
            margin-top: 5px;
            background: map-get($theme1, color-base);
        }
    }
}

#attachPin {
    text-align: center;

    .btn.afu-attach-pin {
        height: auto;
        border: none !important;
        color: white !important;
        background-color: map-get($theme1, color-base) !important;
        border-radius: rem(5px) !important;
        font-size: .89rem !important;
        margin: 20px auto !important;
        padding: .5rem 1.1rem .55rem !important;
    }
}

input.upload-button {
    color: transparent;
    padding-left: 28px;

    &::-webkit-file-upload-button {
        visibility: hidden;
    }

    &::before {
        display: inline-block !important;
        content: 'Seleccionar Archivo' !important;
        cursor: pointer;
        height: auto;
        border: none !important;
        color: white !important;
        background-color: map-get($theme1, color-base) !important;
        border-radius: rem(5px) !important;
        font-size: .89rem !important;
        margin: 20px auto !important;
        padding: .5rem 1.1rem .55rem !important;
        content: 'Select some files';
        display: inline-block;
        white-space: nowrap;
        -webkit-user-select: none;
    }

    &.reupload {
        padding-left: 80px;

        &::before {
            content: 'Seleccionar Otro Archivo' !important;
        }
    }
}

@include mq-desktop {
    input.upload-button {
        &.reupload {
            padding-left: 170px;
        }
    }
}