$shadow: 0.125rem 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.90);
$shadow2: 0rem 0rem 0.5rem 0 rgba(0, 0, 0, 0.1);
$shadow3: -0.125rem 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.25);
.shadow__1 {
    border-radius: 2rem;
    box-shadow: $shadow;
}

.shadow__2 {
    box-shadow: $shadow2;
}

.shadow__3 {
    box-shadow: $shadow3;
}