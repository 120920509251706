@import "../01-settings/colors";
section {
    padding: 0.5rem;
    background-color: map-get($theme1, color-off);
    padding-bottom: 80px;
    &.detail-ad-container {
        padding: 0px;
    }
    &.ad-step {
        background-color: map-get($theme1, color-accent-white);
        padding-top: 0px;
        padding-bottom: 0px;
        .container {
            max-width: 900px;
            padding-bottom: 80px;
            background-color: map-get($theme1, color-off);
        }
    }
    &.no-padding{
        padding: 0px !important;
    }
}