.containerBoxes {
    @include flexbox;
    flex-wrap: wrap;
    .boxDash {
        width: 48%;
        margin-bottom: 2%;
        border-radius: .5rem;
        padding: 1rem;
        background-color: white;
        border: solid 1px map-get($theme1, color-gray-400);
        &.boxVideo {
            width: 100%;
            padding: 0rem;
        }
        &.boxChard {
            overflow: hidden;
            width: 100%;
            height: 19rem;
        }
        &.resume {
            height: auto;
            padding: 0rem !important;
        }
        &.boxAccent {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            @include gradient(bottom, map-get($theme1, color-accent), map-get($theme1, color-accent-lighter));
            color: white;
        }
        &.boxMoney {
            @include flexbox;
            @include justify-content(center);
            @include align-items(center);
            color: map-get($theme1, color-accent);
            font-size: 1.7rem;
            font-weight: 100;
        }
        &.small-box {
            width: 100%;
            h3 {
                text-align: center;
            }
            p {
                text-align: center;
            }
        }
    }
}

@include mq-tablet {
    .containerBoxes {
        .boxDash {
            width: 48%;
            &.boxChard {}
            &.small-box {
                width: 32%;
                margin-right: 1.3%;
            }
        }
    }
}

@include mq-desktop {
    .containerBoxes {
        .boxDash {
            width: 24.5%;
            &.boxVideo {
                width: 24.5%;
            }
            &.boxChard {
                width: 48%;
                height: 22rem;
                margin-right: 2%;
            }
            &.resume {
                padding: 1rem !important;
            }
            &.monthChard {
                width: 100%;
                height: 400px;
            }
            &.small-box {
                width: 32%;
                margin-right: 1.3%;
            }
        }
    }
}