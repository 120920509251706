@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import 'animate.css';
:root {
  --meat-color-primary: #2B0AFF;
  --meat-color-secondary: #03073A;
  --meat-color-tertiary: #5260ff;
  --meat-color-success: #00CC66;
  --meat-color-warning: #ffc409;
  --meat-color-danger: #eb445a;
  --meat-color-dark: #222428;
  --meat-color-medium: #92949c;
  --meat-color-light: #f4f5f8;
  --meat-color-free: #00AB55;
  --meat-color-basic: #FF2E00;
  --mdc-outlined-text-field-outline-color: #D7D9E0;
}

.bck__base {
  background: var(--meat-color-primary);
  color: #fff;
}

.bck__auxiliar {
  background: var(--meat-color-tertiary);
  color: #fff;
}

.bck__accent {
  color: #fff;
}

.bck__off {
  background: #FDFDFD;
  color: var(--meat-color-secondary);
}

.bck__dark {
  background: var(--meat-color-secondary);
  color: #fff;
}

.text-white {
  color: white !important;
}

.text-gray-100 {
  color: #a8a8a8 !important;
}

.text-gray-300 {
  color: #a8a8a8 !important;
}

.text-gray-500 {
  color: #a8a8a8 !important;
}

.bg-gray-100 {
  background-color: #F4F4F4 !important;
}

.bg-gray-300 {
  background-color: #a8a8a8 !important;
}

.bg-gray-500 {
  background-color: #a8a8a8 !important;
}

.dark-bg {
  background: var(--meat-color-dark);
}

.bg-primary-deg {
  background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
  color: white !important;
}
.bg-primary-deg ::ng-deep h2, .bg-primary-deg ::ng-deep p {
  color: white !important;
}

.bg-primary-deg-v {
  background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */
/* Texto en color primario */
.text-primary {
  color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
  color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
  color: var(--meat-color-tertiary);
}

/* Colores de fondo */
/* Fondo en color primario */
.bg-primary {
  background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
  background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
  background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
  background-color: var(--meat-color-free) !important;
}

.bg-basic {
  background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
  background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
  background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
  background-color: var(--meat-color-secondary) !important;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

.shadow__1 {
  border-radius: 2rem;
  box-shadow: 0.125rem 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.9);
}

.shadow__2 {
  box-shadow: 0rem 0rem 0.5rem 0 rgba(0, 0, 0, 0.1);
}

.shadow__3 {
  box-shadow: -0.125rem 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.25);
}

html,
body {
  font-family: "Poppins", sans-serif !important;
  margin: 0;
  overflow-x: hidden;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.not-mobile {
  display: none;
}
@media only screen and (min-width: 640px) and (max-width: 970px) {
  .not-mobile {
    display: block;
  }
}
@media only screen and (min-width: 970px) {
  .not-mobile {
    display: block;
  }
}

.mobile {
  display: block;
}
@media only screen and (min-width: 640px) and (max-width: 970px) {
  .mobile {
    display: none;
  }
}
@media only screen and (min-width: 970px) {
  .mobile {
    display: none;
  }
}

.only-desktop {
  display: none;
}
@media only screen and (min-width: 970px) {
  .only-desktop {
    display: block;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
  animation-duration: 1.5s;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1s;
}

@-webkit-keyframes heartBeat {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes heartBeat {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  35% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.heartBeat {
  -webkit-animation-name: heartBeat;
  animation-name: heartBeat;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  z-index: 2 !important;
}

:root {
  --meat-color-primary: #2B0AFF;
  --meat-color-secondary: #03073A;
  --meat-color-tertiary: #5260ff;
  --meat-color-success: #00CC66;
  --meat-color-warning: #ffc409;
  --meat-color-danger: #eb445a;
  --meat-color-dark: #222428;
  --meat-color-medium: #92949c;
  --meat-color-light: #f4f5f8;
  --meat-color-free: #00AB55;
  --meat-color-basic: #FF2E00;
  --mdc-outlined-text-field-outline-color: #D7D9E0;
}

.bck__base {
  background: var(--meat-color-primary);
  color: #fff;
}

.bck__auxiliar {
  background: var(--meat-color-tertiary);
  color: #fff;
}

.bck__accent {
  color: #fff;
}

.bck__off {
  background: #FDFDFD;
  color: var(--meat-color-secondary);
}

.bck__dark {
  background: var(--meat-color-secondary);
  color: #fff;
}

.text-white {
  color: white !important;
}

.text-gray-100 {
  color: #a8a8a8 !important;
}

.text-gray-300 {
  color: #a8a8a8 !important;
}

.text-gray-500 {
  color: #a8a8a8 !important;
}

.bg-gray-100 {
  background-color: #F4F4F4 !important;
}

.bg-gray-300 {
  background-color: #a8a8a8 !important;
}

.bg-gray-500 {
  background-color: #a8a8a8 !important;
}

.dark-bg {
  background: var(--meat-color-dark);
}

.bg-primary-deg {
  background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
  color: white !important;
}
.bg-primary-deg ::ng-deep h2, .bg-primary-deg ::ng-deep p {
  color: white !important;
}

.bg-primary-deg-v {
  background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */
/* Texto en color primario */
.text-primary {
  color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
  color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
  color: var(--meat-color-tertiary);
}

/* Colores de fondo */
/* Fondo en color primario */
.bg-primary {
  background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
  background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
  background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
  background-color: var(--meat-color-free) !important;
}

.bg-basic {
  background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
  background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
  background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
  background-color: var(--meat-color-secondary) !important;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

a {
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  height: 1.25rem;
  color: var(--meat-color-primary) !important;
  font-family: "Poppins", sans-serif !important;
  cursor: pointer;
}

ul li {
  font-family: "Poppins", sans-serif !important;
}

ol li {
  font-family: "Poppins", sans-serif !important;
}

input,
select,
textarea {
  font-family: "Poppins", sans-serif !important;
}

.errors {
  padding: 0rem 1.7rem;
  font-size: 0.9rem;
  color: red;
  display: block;
}
.errors.noPadding {
  padding: 0rem;
}

.success {
  color: green;
}

.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 12px;
  cursor: pointer;
  font-size: 0.8rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 25px;
  width: 25px;
  background: none;
  border: 1px solid;
}
.checkmark.dark {
  border-color: var(--meat-color-primary);
  /* When the checkbox is checked, add a blue background */
}
.checkmark.dark .container-check:hover input ~ .checkmark {
  background-color: var(--meat-color-tertiary);
}
.checkmark.dark .container-check input:checked ~ .checkmark {
  background-color: var(--meat-color-primary);
}
.checkmark.white {
  /* When the checkbox is checked, add a blue background */
}
.checkmark.white .container-check:hover input ~ .checkmark {
  background-color: #FDFDFD;
}
/* On mouse-over, add a grey background color */
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

:host input:-internal-autofill-selected {
  -webkit-text-fill-color: black !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.9em;
}

::ng-deep .mat-form-field-infix {
  border-top: 0.3rem !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1.15em 0 1em 0;
}

.formNoLines ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px 10px 10px 10px !important;
}
.formNoLines ::ng-deep .mat-form-field-underline {
  display: none;
}
.formNoLines ::ng-deep .mat-form-field {
  line-height: 1 !important;
  padding-bottom: 0.3rem !important;
}
.formNoLines ::ng-deep .mat-form-field-wrapper {
  margin-bottom: -0.3em;
}
.formNoLines ::ng-deep .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0.2em 0.7em 0 0.7em;
}

.mat-form-field .mat-form-field-outline-thick {
  color: #a8a8a8;
}
.mat-form-field:hover .mat-form-field-outline-thick {
  color: var(--meat-color-primary);
}
.mat-form-field.mat-focused .mat-form-field-outline {
  color: var(--meat-color-primary) !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 3px solid !important;
}
.mat-form-field.mat-focused .mat-form-field-outline .mat-form-field-outline-start {
  border-bottom-left-radius: 0px !important;
}
.mat-form-field.mat-focused .mat-form-field-outline .mat-form-field-outline-end {
  border-bottom-right-radius: 0px !important;
}
.mat-form-field.mat-focused .mat-form-field-outline input {
  background-color: white;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline {
  color: red !important;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 3px solid !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-start {
  border-bottom-left-radius: 0px !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline .mat-form-field-outline-end {
  border-bottom-right-radius: 0px !important;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-outline input {
  background-color: white;
}

.material-icons.errors {
  display: inline;
  padding: 0;
  margin-right: 2px;
  font-size: 22px;
  vertical-align: middle;
}

.mat-error,
mat-error {
  color: black;
  font-size: 15px;
}

mat-form-field.mat-form-field-invalid {
  margin-bottom: 15px;
}

.mat-form-field-subscript-wrapper {
  padding: 0.5em 0em !important;
  font-size: 100% !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-radio-button {
  margin-right: 15px;
  margin-top: 10px;
}
.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--meat-color-primary) !important;
}
.mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background-color: var(--meat-color-primary);
  transform: scale(0.6) !important;
}

.mat-mdc-form-field-infix {
  min-height: 20px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--meat-color-primary) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--meat-color-primary) !important;
}

.mat-mdc-text-field-wrapper {
  background-color: white;
}

label {
  font-size: 0.875rem;
  font-weight: 400;
  height: 1.25rem;
  color: #5a5d70;
  font-family: "Poppins", sans-serif !important;
}

.assistive {
  font-size: 0.75rem;
  font-weight: 400;
  height: 1.625rem;
}

p, .mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  font-weight: 400;
  font-size: 1rem;
  color: #333;
  font-family: "Poppins", sans-serif !important;
}
p.small, .mat-expansion-panel-header-title.small, .mat-expansion-panel-header-description.small {
  font-size: 0.875rem;
}
p.big, .mat-expansion-panel-header-title.big, .mat-expansion-panel-header-description.big {
  font-size: 1.25rem;
}
p.jumbo, .mat-expansion-panel-header-title.jumbo, .mat-expansion-panel-header-description.jumbo {
  font-weight: 500;
  font-size: 2.5rem;
}
p.italic, .mat-expansion-panel-header-title.italic, .mat-expansion-panel-header-description.italic {
  font-style: italic;
}
p.bold, .mat-expansion-panel-header-title.bold, .mat-expansion-panel-header-description.bold {
  font-weight: 600;
}
p.bolder, .mat-expansion-panel-header-title.bolder, .mat-expansion-panel-header-description.bolder {
  font-weight: 800;
}

span.mat-mdc-select-placeholder {
  font-family: "Poppins", sans-serif !important;
}
span.tag {
  display: inline-block;
  border-radius: 50px;
  background-color: white;
  border: solid 1px #d7d9e0;
  padding: 0rem 0.6rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}
span.color-dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: inline-block;
}
span.color-dot.active, span.color-dot.playing {
  background-color: #25b825;
}
span.color-dot.review {
  background-color: #ff7029;
}
span.color-dot.waiting {
  background-color: #25b825;
}
span.color-dot.not-launch {
  background-color: #ff7029;
}
span.color-dot.error, span.color-dot.finished, span.color-dot.rejected {
  background-color: #ff573d;
}
span.color-dot.default {
  background-color: var(--meat-color-primary);
}
span.color-dot.border {
  width: 10px;
  height: 10px;
  border: solid 3px #999bad !important;
}
span.material-icons.small {
  font-size: 18px !important;
}

.tabs-header {
  margin-top: 25px;
  padding: 0px 35px;
}
.tabs-header h2 {
  margin-bottom: 0px;
  margin-right: 15px;
  cursor: pointer;
}
.tabs-header h2.selected {
  border-bottom: solid 3px var(--meat-color-primary);
}

:root {
  --meat-color-primary: #2B0AFF;
  --meat-color-secondary: #03073A;
  --meat-color-tertiary: #5260ff;
  --meat-color-success: #00CC66;
  --meat-color-warning: #ffc409;
  --meat-color-danger: #eb445a;
  --meat-color-dark: #222428;
  --meat-color-medium: #92949c;
  --meat-color-light: #f4f5f8;
  --meat-color-free: #00AB55;
  --meat-color-basic: #FF2E00;
  --mdc-outlined-text-field-outline-color: #D7D9E0;
}

.bck__base {
  background: var(--meat-color-primary);
  color: #fff;
}

.bck__auxiliar {
  background: var(--meat-color-tertiary);
  color: #fff;
}

.bck__accent {
  color: #fff;
}

.bck__off {
  background: #FDFDFD;
  color: var(--meat-color-secondary);
}

.bck__dark {
  background: var(--meat-color-secondary);
  color: #fff;
}

.text-white {
  color: white !important;
}

.text-gray-100 {
  color: #a8a8a8 !important;
}

.text-gray-300 {
  color: #a8a8a8 !important;
}

.text-gray-500 {
  color: #a8a8a8 !important;
}

.bg-gray-100 {
  background-color: #F4F4F4 !important;
}

.bg-gray-300 {
  background-color: #a8a8a8 !important;
}

.bg-gray-500 {
  background-color: #a8a8a8 !important;
}

.dark-bg {
  background: var(--meat-color-dark);
}

.bg-primary-deg {
  background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
  color: white !important;
}
.bg-primary-deg ::ng-deep h2, .bg-primary-deg ::ng-deep p {
  color: white !important;
}

.bg-primary-deg-v {
  background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */
/* Texto en color primario */
.text-primary {
  color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
  color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
  color: var(--meat-color-tertiary);
}

/* Colores de fondo */
/* Fondo en color primario */
.bg-primary {
  background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
  background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
  background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
  background-color: var(--meat-color-free) !important;
}

.bg-basic {
  background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
  background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
  background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
  background-color: var(--meat-color-secondary) !important;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

.display {
  font-size: 1.5rem;
  font-weight: 400;
  min-height: 2.375rem;
  color: black;
}

hr.dashed {
  border: dashed 1px #F2F2F3;
}

.border-bottom-gray {
  border-bottom: 1px solid #d7d9e0;
}

h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}
h1.light {
  color: white;
}

h2 {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}
h2.light {
  color: white;
}
h2.big {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}
h2.bold {
  font-weight: 600;
}
h2.bolder {
  font-weight: 800;
}

h3 {
  font-size: 1rem;
  font-weight: 600;
  height: 1.625rem;
  color: #333;
}

h4 {
  font-size: 0.875rem;
  font-weight: 600;
  height: 1.5rem;
  color: #333;
}

h4 {
  font-size: 0.875rem;
  font-weight: 600;
  height: 1.25rem;
  color: black;
}

:root {
  --meat-color-primary: #2B0AFF;
  --meat-color-secondary: #03073A;
  --meat-color-tertiary: #5260ff;
  --meat-color-success: #00CC66;
  --meat-color-warning: #ffc409;
  --meat-color-danger: #eb445a;
  --meat-color-dark: #222428;
  --meat-color-medium: #92949c;
  --meat-color-light: #f4f5f8;
  --meat-color-free: #00AB55;
  --meat-color-basic: #FF2E00;
  --mdc-outlined-text-field-outline-color: #D7D9E0;
}

.bck__base {
  background: var(--meat-color-primary);
  color: #fff;
}

.bck__auxiliar {
  background: var(--meat-color-tertiary);
  color: #fff;
}

.bck__accent {
  color: #fff;
}

.bck__off {
  background: #FDFDFD;
  color: var(--meat-color-secondary);
}

.bck__dark {
  background: var(--meat-color-secondary);
  color: #fff;
}

.text-white {
  color: white !important;
}

.text-gray-100 {
  color: #a8a8a8 !important;
}

.text-gray-300 {
  color: #a8a8a8 !important;
}

.text-gray-500 {
  color: #a8a8a8 !important;
}

.bg-gray-100 {
  background-color: #F4F4F4 !important;
}

.bg-gray-300 {
  background-color: #a8a8a8 !important;
}

.bg-gray-500 {
  background-color: #a8a8a8 !important;
}

.dark-bg {
  background: var(--meat-color-dark);
}

.bg-primary-deg {
  background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
  color: white !important;
}
.bg-primary-deg ::ng-deep h2, .bg-primary-deg ::ng-deep p {
  color: white !important;
}

.bg-primary-deg-v {
  background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */
/* Texto en color primario */
.text-primary {
  color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
  color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
  color: var(--meat-color-tertiary);
}

/* Colores de fondo */
/* Fondo en color primario */
.bg-primary {
  background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
  background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
  background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
  background-color: var(--meat-color-free) !important;
}

.bg-basic {
  background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
  background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
  background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
  background-color: var(--meat-color-secondary) !important;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

.btn-primary {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: white;
  background-color: var(--meat-color-primary) !important;
}
.btn-primary:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}
.btn-primary.disabled {
  color: #5a5d70;
  background-color: #d7d9e0 !important;
}
.btn-primary.disabled:hover {
  opacity: 1;
  transform: scale(1);
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
}

.btn-plain {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: var(--meat-color-primary) !important;
  background-color: white !important;
}
.btn-plain:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}
.btn-plain:focus-visible {
  outline: none;
}

.btn-primary-disabled {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid 1px;
  color: #999bad;
  background-color: #dcd6ff !important;
}
.btn-primary-disabled:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-primary-extended {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  width: 100%;
  border: none;
  color: white;
  background-color: var(--meat-color-primary) !important;
}
.btn-primary-extended:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-primary-extended-disabled {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  width: 100%;
  border: none;
  color: white;
  background-color: #dcd6ff !important;
}
.btn-primary-extended-disabled:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-primary-700 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: white;
  background-color: #071ba3 !important;
}
.btn-primary-700:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-primary-900 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: white;
  background-color: #040f5c !important;
}
.btn-primary-900:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary-gray {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid #bcbecc 1px;
  background-color: white !important;
}
.btn-secondary-gray:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-circle-secondary-gray {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid #bcbecc 1px;
  background-color: white !important;
  border-radius: 50px;
}
.btn-circle-secondary-gray:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-circle-secondary-gray-inverted {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid #bcbecc 1px;
  color: var(--meat-color-primary);
  background-color: !important;
  border-radius: 50px;
}
.btn-circle-secondary-gray-inverted:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}
.btn-circle-secondary-gray-inverted span {
  color: var(--meat-color-primary);
}

.btn-secondary-primary {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid var(--meat-color-primary) 1px;
  background-color: white !important;
}
.btn-secondary-primary:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary-dark {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid #040f5c 1px;
  background-color: white !important;
}
.btn-secondary-dark:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-secondary-disabled {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: solid #bcbecc 1px;
  color: #999bad;
  background-color: #edeef5 !important;
}
.btn-secondary-disabled:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-tertiary-primary {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: var(--meat-color-primary);
  background-color: transparent !important;
}
.btn-tertiary-primary:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-tertiary-primary-700 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: var(--meat-color-primary);
  background-color: transparent !important;
}
.btn-tertiary-primary-700:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-tertiary-primary-900 {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: var(--meat-color-primary);
  background-color: transparent !important;
}
.btn-tertiary-primary-900:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-tertiary-disabled {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: #999bad;
  background-color: transparent !important;
}
.btn-tertiary-disabled:hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}

.btn-google {
  font-size: 0.89rem;
  font-weight: bold;
  padding: 0.3rem 1.1rem 0.3rem;
  line-height: 1.25rem;
  border-radius: 0.3125rem;
  cursor: pointer;
  width: 100%;
  border: solid #bcbecc 1.5px;
  color: black;
  background-color: white !important;
}

.circleButton {
  display: flex;
  align-items: center;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--meat-color-primary);
}
.circleButton span {
  color: white;
}

.iconButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
}
.btn-border-blue-bottom {
  padding: 2px 0px;
  margin: auto 10px;
  font-size: 1rem;
  line-height: 1.25rem;
  border: none;
  background: none !important;
  cursor: pointer;
  color: #5A5D70;
  overflow: visible;
}
.btn-border-blue-bottom.active {
  color: black !important;
  font-weight: bold;
}
.btn-border-blue-bottom.active::after {
  display: block;
  content: "";
  width: 100%;
  height: 4px;
  border-radius: 10px;
  margin-top: 5px;
  background: var(--meat-color-primary);
}

#attachPin {
  text-align: center;
}
#attachPin .btn.afu-attach-pin {
  height: auto;
  border: none !important;
  color: white !important;
  background-color: var(--meat-color-primary) !important;
  border-radius: 0.3125rem !important;
  font-size: 0.89rem !important;
  margin: 20px auto !important;
  padding: 0.5rem 1.1rem 0.55rem !important;
}

input.upload-button {
  color: transparent;
  padding-left: 28px;
}
input.upload-button::-webkit-file-upload-button {
  visibility: hidden;
}
input.upload-button::before {
  display: inline-block !important;
  content: "Seleccionar Archivo" !important;
  cursor: pointer;
  height: auto;
  border: none !important;
  color: white !important;
  background-color: var(--meat-color-primary) !important;
  border-radius: 0.3125rem !important;
  font-size: 0.89rem !important;
  margin: 20px auto !important;
  padding: 0.5rem 1.1rem 0.55rem !important;
  content: "Select some files";
  display: inline-block;
  white-space: nowrap;
  -webkit-user-select: none;
}
input.upload-button.reupload {
  padding-left: 80px;
}
input.upload-button.reupload::before {
  content: "Seleccionar Otro Archivo" !important;
}

@media only screen and (min-width: 970px) {
  input.upload-button.reupload {
    padding-left: 170px;
  }
}
:root {
  --meat-color-primary: #2B0AFF;
  --meat-color-secondary: #03073A;
  --meat-color-tertiary: #5260ff;
  --meat-color-success: #00CC66;
  --meat-color-warning: #ffc409;
  --meat-color-danger: #eb445a;
  --meat-color-dark: #222428;
  --meat-color-medium: #92949c;
  --meat-color-light: #f4f5f8;
  --meat-color-free: #00AB55;
  --meat-color-basic: #FF2E00;
  --mdc-outlined-text-field-outline-color: #D7D9E0;
}

.bck__base {
  background: var(--meat-color-primary);
  color: #fff;
}

.bck__auxiliar {
  background: var(--meat-color-tertiary);
  color: #fff;
}

.bck__accent {
  color: #fff;
}

.bck__off {
  background: #FDFDFD;
  color: var(--meat-color-secondary);
}

.bck__dark {
  background: var(--meat-color-secondary);
  color: #fff;
}

.text-white {
  color: white !important;
}

.text-gray-100 {
  color: #a8a8a8 !important;
}

.text-gray-300 {
  color: #a8a8a8 !important;
}

.text-gray-500 {
  color: #a8a8a8 !important;
}

.bg-gray-100 {
  background-color: #F4F4F4 !important;
}

.bg-gray-300 {
  background-color: #a8a8a8 !important;
}

.bg-gray-500 {
  background-color: #a8a8a8 !important;
}

.dark-bg {
  background: var(--meat-color-dark);
}

.bg-primary-deg {
  background: linear-gradient(270deg, var(--meat-color-primary) 0%, var(--meat-color-secondary) 100%);
  color: white !important;
}
.bg-primary-deg ::ng-deep h2, .bg-primary-deg ::ng-deep p {
  color: white !important;
}

.bg-primary-deg-v {
  background: linear-gradient(164.52deg, var(--meat-color-primary) -2.85%, var(--meat-color-secondary) 61.24%);
}

/* Colores de texto */
/* Texto en color primario */
.text-primary {
  color: var(--meat-color-primary);
}

/* Texto en color secundario */
.text-secondary {
  color: var(--meat-color-secondary);
}

/* Texto en color terciario */
.text-tertiary {
  color: var(--meat-color-tertiary);
}

/* Colores de fondo */
/* Fondo en color primario */
.bg-primary {
  background-color: var(--meat-color-primary) !important;
}

/* Fondo en color secundario */
.bg-secondary {
  background-color: var(--meat-color-secondary) !important;
}

/* Fondo en color terciario */
.bg-tertiary {
  background-color: var(--meat-color-tertiary) !important;
}

.bg-free {
  background-color: var(--meat-color-free) !important;
}

.bg-basic {
  background-color: var(--meat-color-basic) !important;
}

.bg-sampling {
  background-color: var(--meat-color-free) !important;
}

.bg-marketplace {
  background-color: var(--meat-color-primary) !important;
}

.bg-redirection {
  background-color: var(--meat-color-secondary) !important;
}

.text-error {
  color: red !important;
}

.text-success {
  color: green !important;
}

section {
  padding: 0.5rem;
  background-color: #FDFDFD;
  padding-bottom: 80px;
}
section.detail-ad-container {
  padding: 0px;
}
section.ad-step {
  background-color: #f7f8fa;
  padding-top: 0px;
  padding-bottom: 0px;
}
section.ad-step .container {
  max-width: 900px;
  padding-bottom: 80px;
  background-color: #FDFDFD;
}
section.no-padding {
  padding: 0px !important;
}

#cdk-overlay-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}
#cdk-overlay-0 .mat-menu-panel {
  background-color: var(--meat-color-secondary);
}
#cdk-overlay-0 .mat-menu-panel .mat-menu-content {
  width: 100% !important;
  padding: 1rem;
}
#cdk-overlay-0 .mat-menu-panel .mat-menu-content .mat-menu-item {
  font-weight: 100;
  color: white;
  font-size: 1rem !important;
  padding: 0.5rem !important;
  width: 100% !important;
  text-align: end;
}

::ng-deep .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--meat-color-secondary);
}

::ng-deep .ngx-charts-outer {
  display: flex;
  align-content: center;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

::ng-deep .chart-legend .legend-wrap {
  width: auto !important;
}

::ng-deep .chart-legend {
  width: 100% !important;
}
::ng-deep .chart-legend div {
  width: 100% !important;
}

::ng-deep .chart-legend .legend-labels {
  display: flex;
  width: auto !important;
}

::ng-deep .chart-legend .legend-title-text {
  font-size: smaller;
  width: 8rem !important;
}

::ng-deep .generic-modalbox mat-dialog-container {
  padding: 0 !important;
}

::ng-deep .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 20px;
}

.containerList {
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #d7d9e0;
}
.containerList.no-bottom-border {
  border-bottom: none;
}
.containerList:hover {
  background-color: rgb(245, 245, 255);
}
.containerList.validated {
  background-color: rgb(242, 242, 255);
}
.containerList .containerImage {
  height: 3rem;
  width: 3rem;
  border-radius: 3rem;
}
.containerList .containerImage img {
  height: 100%;
  width: 100%;
}
.containerList .containerImageDescription {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 3rem;
  width: 5rem;
}
.containerList .containerImageDescription img {
  height: 2rem;
}
.containerList .containerImageDescription .imageDescription {
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 100;
  color: #bcbecc;
}
.containerList .dataList {
  text-align: left;
  font-size: 0.85rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 20%;
  padding: 0.2rem;
}
.containerList .dataList.name {
  text-align: left;
  font-size: 1.1rem;
  line-height: 1;
}
.containerList.list-header {
  display: none;
  height: auto;
  background-color: #f7f8fa;
  border-bottom: solid 2px #d7d9e0 !important;
}
.containerList.list-header .dataList {
  border-left: 1px solid #d7d9e0;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  color: #5a5d70;
}
.containerList.list-header .dataList:nth-child(1) {
  border-left: none;
}
.containerList .date-timeline {
  position: relative;
}
.containerList .date-timeline::before {
  content: "";
  position: absolute;
  height: 15px;
  top: 17px;
  left: 2px;
  width: 1px;
  border-left: dashed 1px #5a5d70;
}
.containerList .date-timeline span {
  display: block;
}
.containerList .date-timeline span::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: solid 2px #5a5d70;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: -2px;
}
.containerList .date-timeline span:nth-child(2)::before {
  background-color: #5a5d70;
}

#table-filters {
  display: none;
  justify-content: space-between;
  flex-direction: column;
}
#table-filters .mat-form-field-flex {
  flex-direction: row-reverse;
}
#table-filters .date-filter .mat-form-field-flex,
#table-filters .search-bar .mat-form-field-flex {
  flex-direction: row-reverse;
}
#table-filters .mat-form-field {
  margin: 0 8px;
  max-width: 65px;
}
#table-filters .search-bar {
  max-width: 300px;
}

.ad-thumbnail {
  min-width: 57px;
  min-height: 97px;
  width: 57px;
  max-height: fit-content;
  border-radius: 5px;
  padding: 1px;
  border: solid black 1px;
  margin-right: 10px;
  background-color: #f7f8fa;
}

@media only screen and (min-width: 640px) and (max-width: 970px) {
  .containerList.list-header {
    display: flex;
  }
  .containerList .dataList {
    font-size: 0.9rem;
    width: auto;
    padding: 0.5rem 0.9rem;
  }
  .containerList .date-timeline::before {
    left: -1px;
    height: 25px;
  }
  .containerList .date-timeline span::before {
    margin-left: -5px;
  }
  #table-filters {
    display: flex;
    flex-direction: row;
  }
  #table-filters .mat-form-field-flex .mat-icon {
    display: none;
  }
  #table-filters .date-filter .mat-form-field-flex,
  #table-filters .search-bar .mat-form-field-flex {
    flex-direction: row-reverse;
  }
  #table-filters .date-filter .mat-form-field-flex .mat-icon,
  #table-filters .search-bar .mat-form-field-flex .mat-icon {
    display: block;
  }
  #table-filters .mat-form-field {
    margin: 0 8px;
    max-width: 120px;
  }
  #table-filters .search-bar {
    max-width: 450px;
  }
  .ad-thumbnail {
    min-width: 38px;
    min-height: 59px;
    width: 38px;
  }
}
@media only screen and (min-width: 970px) {
  .containerList.list-header {
    display: flex;
  }
  .containerList .dataList {
    font-size: 1rem;
    width: auto;
    padding: 0.5rem 0.9rem;
  }
  .containerList .date-timeline::before {
    left: -1px;
    height: 25px;
  }
  .containerList .date-timeline span::before {
    margin-left: -5px;
  }
  #table-filters {
    display: flex;
    flex-direction: row;
  }
  #table-filters .mat-form-field-flex .mat-icon {
    display: none;
  }
  #table-filters .date-filter .mat-form-field-flex,
  #table-filters .search-bar .mat-form-field-flex {
    flex-direction: row-reverse;
  }
  #table-filters .date-filter .mat-form-field-flex .mat-icon,
  #table-filters .search-bar .mat-form-field-flex .mat-icon {
    display: block;
  }
  #table-filters .mat-form-field {
    margin: 0 8px;
    max-width: 120px;
  }
  #table-filters .search-bar {
    max-width: 450px;
  }
  .ad-thumbnail {
    min-width: 38px;
    min-height: 59px;
    width: 38px;
  }
}
.containerBoxes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.containerBoxes .boxDash {
  width: 48%;
  margin-bottom: 2%;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: white;
  border: solid 1px #d7d9e0;
}
.containerBoxes .boxDash.boxVideo {
  width: 100%;
  padding: 0rem;
}
.containerBoxes .boxDash.boxChard {
  overflow: hidden;
  width: 100%;
  height: 19rem;
}
.containerBoxes .boxDash.resume {
  height: auto;
  padding: 0rem !important;
}
.containerBoxes .boxDash.boxAccent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  /* IE10+ */
  background-image: -ms-linear-gradient(top, 0%, 100%);
  /* Mozilla Firefox */
  background-image: -moz-linear-gradient(top, 0%, 100%);
  /* Opera */
  background-image: -o-linear-gradient(top, 0%, 100%);
  /* Webkit (Safari/Chrome 10) */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, ), color-stop(100, ));
  /* Webkit (Chrome 11+) */
  background-image: -webkit-linear-gradient(top, 0%, 100%);
  /* W3C Markup */
  background-image: linear-gradient(to bottom, 0%, 100%);
  color: white;
}
.containerBoxes .boxDash.boxMoney {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 100;
}
.containerBoxes .boxDash.small-box {
  width: 100%;
}
.containerBoxes .boxDash.small-box h3 {
  text-align: center;
}
.containerBoxes .boxDash.small-box p {
  text-align: center;
}

@media only screen and (min-width: 640px) and (max-width: 970px) {
  .containerBoxes .boxDash {
    width: 48%;
  }
  .containerBoxes .boxDash.small-box {
    width: 32%;
    margin-right: 1.3%;
  }
}
@media only screen and (min-width: 970px) {
  .containerBoxes .boxDash {
    width: 24.5%;
  }
  .containerBoxes .boxDash.boxVideo {
    width: 24.5%;
  }
  .containerBoxes .boxDash.boxChard {
    width: 48%;
    height: 22rem;
    margin-right: 2%;
  }
  .containerBoxes .boxDash.resume {
    padding: 1rem !important;
  }
  .containerBoxes .boxDash.monthChard {
    width: 100%;
    height: 400px;
  }
  .containerBoxes .boxDash.small-box {
    width: 32%;
    margin-right: 1.3%;
  }
}
.containerLine {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
}
.containerLine .line {
  -webkit-text-fill-color: black !important;
  width: 2.3rem;
  height: 0.4rem;
  background-color: var(--meat-color-primary);
}

header.legend-title.ng-star-inserted {
  display: none !important;
}

.chart-legend .legend-label-text, .chart-legend .legend-title-text {
  color: black;
  font-weight: 600;
}

::ng-deep .legend-labels {
  background: none !important;
}
::ng-deep .ngx-ui-tour_backdrop {
  background-color: var(--meat-color-primary) !important;
  opacity: 0.1;
}
::ng-deep .cdk-overlay-container {
  z-index: 9999;
}
::ng-deep .cdk-overlay-container .title-text {
  color: var(--meat-color-secondary) !important;
}
::ng-deep .cdk-overlay-container .mat-button-base:not(.mat-icon-button) {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1.1rem 0.55rem;
  line-height: 1.25rem;
  border-radius: 1.5625rem;
  cursor: pointer;
  min-height: 3rem;
  min-width: 12.875rem;
  height: fit-content;
  -webkit-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.36);
  transition: all 0.2s ease-out;
  border: none;
  color: white;
  background-color: var(--meat-color-primary) !important;
}
::ng-deep .cdk-overlay-container .mat-button-base:not(.mat-icon-button):hover {
  opacity: 0.8;
  transform: scale(1.05);
  -webkit-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 7px 7px 0px rgba(0, 0, 0, 0.2);
}
::ng-deep .cdk-overlay-container .mat-button-base:not(.mat-icon-button).mat-button-disabled {
  background-color: #FDFDFD !important;
  color: gray;
}
::ng-deep .mat-select-panel {
  background-color: white;
}

.top-end {
  top: 20px;
  right: 20px;
}

::ng-deep .mat-mdc-form-field-subscript-wrapper {
  height: 5px;
}

::ng-deep mat-label {
  font-size: 14px;
  color: #333 !important;
  margin-bottom: 10px;
}

.cdk-global-scrollblock {
  top: 0px !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #2b0aff;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #5260ff;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #2b0aff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2b0aff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #5260ff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #5260ff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #5260ff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #5260ff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2b0aff;
  --mdc-linear-progress-track-color: rgba(43, 10, 255, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #5260ff;
  --mdc-linear-progress-track-color: rgba(82, 96, 255, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #2b0aff;
  --mdc-filled-text-field-focus-active-indicator-color: #2b0aff;
  --mdc-filled-text-field-focus-label-text-color: rgba(43, 10, 255, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #2b0aff;
  --mdc-outlined-text-field-focus-outline-color: #2b0aff;
  --mdc-outlined-text-field-focus-label-text-color: rgba(43, 10, 255, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(43, 10, 255, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #5260ff;
  --mdc-filled-text-field-focus-active-indicator-color: #5260ff;
  --mdc-filled-text-field-focus-label-text-color: rgba(82, 96, 255, 0.87);
  --mdc-outlined-text-field-caret-color: #5260ff;
  --mdc-outlined-text-field-focus-outline-color: #5260ff;
  --mdc-outlined-text-field-focus-label-text-color: rgba(82, 96, 255, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(82, 96, 255, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(43, 10, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(82, 96, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #2b0aff;
  --mdc-chip-elevated-disabled-container-color: #2b0aff;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: #5260ff;
  --mdc-chip-elevated-disabled-container-color: #5260ff;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-handle-opacity: 0.38;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #2b0aff;
  --mdc-switch-selected-handle-color: #2b0aff;
  --mdc-switch-selected-hover-state-layer-color: #2b0aff;
  --mdc-switch-selected-pressed-state-layer-color: #2b0aff;
  --mdc-switch-selected-focus-handle-color: #2b0aff;
  --mdc-switch-selected-hover-handle-color: #2b0aff;
  --mdc-switch-selected-pressed-handle-color: #2b0aff;
  --mdc-switch-selected-focus-track-color: #2b0aff;
  --mdc-switch-selected-hover-track-color: #2b0aff;
  --mdc-switch-selected-pressed-track-color: #2b0aff;
  --mdc-switch-selected-track-color: #2b0aff;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #2b0aff;
  --mdc-switch-selected-handle-color: #2b0aff;
  --mdc-switch-selected-hover-state-layer-color: #2b0aff;
  --mdc-switch-selected-pressed-state-layer-color: #2b0aff;
  --mdc-switch-selected-focus-handle-color: #2b0aff;
  --mdc-switch-selected-hover-handle-color: #2b0aff;
  --mdc-switch-selected-pressed-handle-color: #2b0aff;
  --mdc-switch-selected-focus-track-color: #2b0aff;
  --mdc-switch-selected-hover-track-color: #2b0aff;
  --mdc-switch-selected-pressed-track-color: #2b0aff;
  --mdc-switch-selected-track-color: #2b0aff;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2b0aff;
  --mdc-radio-selected-hover-icon-color: #2b0aff;
  --mdc-radio-selected-icon-color: #2b0aff;
  --mdc-radio-selected-pressed-icon-color: #2b0aff;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #2b0aff;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5260ff;
  --mdc-radio-selected-hover-icon-color: #5260ff;
  --mdc-radio-selected-icon-color: #5260ff;
  --mdc-radio-selected-pressed-icon-color: #5260ff;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #5260ff;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #2b0aff;
  --mdc-slider-focus-handle-color: #2b0aff;
  --mdc-slider-hover-handle-color: #2b0aff;
  --mdc-slider-active-track-color: #2b0aff;
  --mdc-slider-inactive-track-color: #2b0aff;
  --mdc-slider-with-tick-marks-inactive-container-color: #2b0aff;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #2b0aff;
  --mat-slider-hover-state-layer-color: rgba(43, 10, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(43, 10, 255, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #5260ff;
  --mat-slider-hover-state-layer-color: rgba(82, 96, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(82, 96, 255, 0.2);
  --mdc-slider-handle-color: #5260ff;
  --mdc-slider-focus-handle-color: #5260ff;
  --mdc-slider-hover-handle-color: #5260ff;
  --mdc-slider-active-track-color: #5260ff;
  --mdc-slider-inactive-track-color: #5260ff;
  --mdc-slider-with-tick-marks-inactive-container-color: #5260ff;
  --mdc-slider-with-tick-marks-active-container-color: black;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mat-menu-container-shape: 4px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #2b0aff;
  --mdc-radio-selected-hover-icon-color: #2b0aff;
  --mdc-radio-selected-icon-color: #2b0aff;
  --mdc-radio-selected-pressed-icon-color: #2b0aff;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #5260ff;
  --mdc-radio-selected-hover-icon-color: #5260ff;
  --mdc-radio-selected-icon-color: #5260ff;
  --mdc-radio-selected-pressed-icon-color: #5260ff;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2b0aff;
  --mdc-checkbox-selected-hover-icon-color: #2b0aff;
  --mdc-checkbox-selected-icon-color: #2b0aff;
  --mdc-checkbox-selected-pressed-icon-color: #2b0aff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2b0aff;
  --mdc-checkbox-selected-hover-state-layer-color: #2b0aff;
  --mdc-checkbox-selected-pressed-state-layer-color: #2b0aff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5260ff;
  --mdc-checkbox-selected-hover-icon-color: #5260ff;
  --mdc-checkbox-selected-icon-color: #5260ff;
  --mdc-checkbox-selected-pressed-icon-color: #5260ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5260ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5260ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5260ff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #2b0aff;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #2b0aff;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator {
  --mat-form-field-container-height: 40px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-filled-with-label-container-padding-top: 8px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #2b0aff;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #2b0aff;
  --mat-tab-header-active-ripple-color: #2b0aff;
  --mat-tab-header-inactive-ripple-color: #2b0aff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #2b0aff;
  --mat-tab-header-active-hover-label-text-color: #2b0aff;
  --mat-tab-header-active-focus-indicator-color: #2b0aff;
  --mat-tab-header-active-hover-indicator-color: #2b0aff;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #5260ff;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #5260ff;
  --mat-tab-header-active-ripple-color: #5260ff;
  --mat-tab-header-inactive-ripple-color: #5260ff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #5260ff;
  --mat-tab-header-active-hover-label-text-color: #5260ff;
  --mat-tab-header-active-focus-indicator-color: #5260ff;
  --mat-tab-header-active-hover-indicator-color: #5260ff;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #2b0aff;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #5260ff;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #5260ff;
  --mdc-checkbox-selected-hover-icon-color: #5260ff;
  --mdc-checkbox-selected-icon-color: #5260ff;
  --mdc-checkbox-selected-pressed-icon-color: #5260ff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #5260ff;
  --mdc-checkbox-selected-hover-state-layer-color: #5260ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #5260ff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #2b0aff;
  --mdc-checkbox-selected-hover-icon-color: #2b0aff;
  --mdc-checkbox-selected-icon-color: #2b0aff;
  --mdc-checkbox-selected-pressed-icon-color: #2b0aff;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #2b0aff;
  --mdc-checkbox-selected-hover-state-layer-color: #2b0aff;
  --mdc-checkbox-selected-pressed-state-layer-color: #2b0aff;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}

html {
  --mdc-text-button-label-text-color: #000;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: #000;
  --mat-text-button-disabled-state-layer-color: #000;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #000;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: #000;
  --mat-filled-button-disabled-state-layer-color: #000;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #000;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: #000;
  --mat-protected-button-disabled-state-layer-color: #000;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #000;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #000;
  --mat-outlined-button-disabled-state-layer-color: #000;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #2b0aff;
  --mat-text-button-state-layer-color: #2b0aff;
  --mat-text-button-ripple-color: rgba(43, 10, 255, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #5260ff;
  --mat-text-button-state-layer-color: #5260ff;
  --mat-text-button-ripple-color: rgba(82, 96, 255, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #2b0aff;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #5260ff;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
  --mat-filled-button-state-layer-color: #fff;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #2b0aff;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #5260ff;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #2b0aff;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #2b0aff;
  --mat-outlined-button-ripple-color: rgba(43, 10, 255, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #5260ff;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #5260ff;
  --mat-outlined-button-ripple-color: rgba(82, 96, 255, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
}

html {
  --mdc-icon-button-state-layer-size: 48px;
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: #000;
  --mat-icon-button-disabled-state-layer-color: #000;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #2b0aff;
  --mat-icon-button-state-layer-color: #2b0aff;
  --mat-icon-button-ripple-color: rgba(43, 10, 255, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #5260ff;
  --mat-icon-button-state-layer-color: #5260ff;
  --mat-icon-button-ripple-color: rgba(82, 96, 255, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: #000;
  --mat-fab-disabled-state-layer-color: #000;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary,
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #2b0aff;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent,
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #5260ff;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn,
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: #fff;
  --mat-fab-state-layer-color: #fff;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #5260ff;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #2b0aff;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #5260ff;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-background-color: #2b0aff;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #5260ff;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #2b0aff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(43, 10, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(43, 10, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(43, 10, 255, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #2b0aff;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(43, 10, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #5260ff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(82, 96, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(82, 96, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(82, 96, 255, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(82, 96, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #5260ff;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #2b0aff;
}
.mat-icon.mat-accent {
  --mat-icon-color: #5260ff;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #2b0aff;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #2b0aff;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #2b0aff;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: #5260ff;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: #5260ff;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: #5260ff;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #2b0aff;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #5260ff;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}