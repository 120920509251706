p,.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
    font-weight: 400;
    font-size: rem(16px);
    color: map-get($theme2, black);
    font-family: "Poppins", sans-serif !important;
    &.small{
        font-size: rem(14px);
    }
    &.big{
        font-size: rem(20px);
    }
    &.jumbo{
        font-weight: 500;
        font-size: rem(40px);
    }
    &.italic{
        font-style: italic;
    }
    &.bold{
        font-weight: 600;
    }
    &.bolder{
        font-weight: 800 ;
    }
}

span {
    &.mat-mdc-select-placeholder{
        font-family: "Poppins", sans-serif !important;
    }
    &.tag {
        display: inline-block;
        border-radius: 50px;
        background-color: white;
        border: solid 1px map-get($theme1, color-gray-400);
        padding: 0rem .6rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 25px;
    }
    &.color-dot {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        display: inline-block;
        &.active,&.playing {
            background-color: map-get($theme1, color-oliva-600);
        }
        &.review {
            background-color: map-get($theme1, color-mandarina-600);
        }
        &.waiting {
            background-color: map-get($theme1, color-oliva-600);
        }
        &.not-launch{
            background-color: map-get($theme1, color-mandarina-600);
        }
        &.error,&.finished,&.rejected{
            background-color: map-get($theme1, color-cereza-500);
        }
        &.default{
            background-color: map-get($theme1, color-base);
        }
        &.border {
            width: 10px;
            height: 10px;
            border: solid 3px map-get($theme1, color-gray-600) !important;
        }
    }
    &.material-icons {
        &.small {
            font-size: 18px !important;
        }
    }
}

.tabs-header {
    margin-top: 25px;
    padding: 0px 35px;
    h2 {
        margin-bottom: 0px;
        margin-right: 15px;
        cursor: pointer;
        &.selected {
            border-bottom: solid 3px map-get($theme1, color-base);
        }
    }
}