.not-mobile {
    display: none;
    @include mq-tablet {
        display: block;
    }
    @include mq-desktop {
        display: block;
    }
}

.mobile {
    display: block;
    @include mq-tablet {
        display: none;
    }
    @include mq-desktop {
        display: none;
    }
}

.only-desktop {
    display: none;
    @include mq-desktop {
        display: block;
    }
}