@import '01-settings/index';
@import '02-tools/index';
@import '03-elements/index';
@import 'animate.css';

::ng-deep {
    .legend-labels {
        background: none !important;
    }
    .ngx-ui-tour_backdrop{
        background-color: map-get($theme1, color-base ) !important;
        opacity: .1;
    }
    .cdk-overlay-container{
        z-index: 9999;
        .title-text{
            color: map-get($theme1, color-dark) !important;
        }
        .mat-button-base:not(.mat-icon-button){
            @include btn-meat;
            border: none;
            color: white;
            background-color: map-get($theme1, color-base) !important;
            &.mat-button-disabled{
                background-color: map-get($theme1, color-off) !important;
                color: gray;
            }
            
        }
    }

    .mat-select-panel{
        background-color: white;
    }
}

.top-end {
    top: 20px;
    right: 20px;
}

::ng-deep .mat-mdc-form-field-subscript-wrapper {
    height: 5px;
}

::ng-deep mat-label {
    font-size: 14px;
    color: map-get($theme2, black) !important;
    margin-bottom: 10px;
}

.cdk-global-scrollblock{
    top: 0px !important;
}