label {
    font-size: rem(14px);
    font-weight: 400;
    height: rem(20px);
    color: map-get($theme1, color-gray-800);
    font-family: "Poppins", sans-serif !important;
}

.labelLight {
    color: map-get($theme1, color-gray-500);
}

.assistive {
    font-size: rem(12px);
    font-weight: 400;
    height: rem(26px);
    color: map-get($theme1, color-gray-950);
}
