ul {
    li {
        font-family: "Poppins", sans-serif !important;
    }
}

ol {
    li {
        font-family: "Poppins", sans-serif !important;
    }
}