#cdk-overlay-0 {
    @include flexbox;
    @include justify-content(flex-end);
    .mat-menu-panel {
        background-color: map-get($theme1, color-dark);
        .mat-menu-content {
            width: 100% !important;
            padding: 1rem;
            .mat-menu-item {
                font-weight: 100;
                color: white;
                font-size: 1rem !important;
                padding: 0.5rem !important;
                width: 100% !important;
                text-align: end;
            }
        }
    }
}

::ng-deep .mat-progress-spinner circle,
.mat-spinner circle {
    stroke: map-get($theme1, color-dark);
}

::ng-deep .ngx-charts-outer {
    display: flex;
    align-content: center;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

::ng-deep .chart-legend .legend-wrap {
    width: auto !important;
}

::ng-deep .chart-legend {
    width: 100% !important;
    div{
        width: 100% !important;
    }
}

::ng-deep .chart-legend .legend-labels {
    display: flex;
    width: auto !important;
}

::ng-deep .chart-legend .legend-title-text {
    font-size: smaller;
    width: 8rem !important;
}

::ng-deep .generic-modalbox {
    mat-dialog-container {
        padding: 0 !important;
    }
}

::ng-deep .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 20px;
}